import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";
import { useAuth0 } from '@auth0/auth0-react'
import { Navigate } from 'react-router-dom'

import { savePetition, sendEmail } from '../services'

import Loading from './Loading';
import Confirmation from './Confirmation';

const FormPetition = (props) => {

    const [isChecked, setIsChecked] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [mountValue, setMountValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [finalizado, setFinalizado] = useState(false);
    const { user, isAuthenticated } = useAuth0()
    const [time, setTime] = useState(new Date().toLocaleString());

    useEffect(() => {
        const parsedValue = parseInt(mountValue, 10);
        if (isChecked || parsedValue > 20000) {
            setInputValue('S/ 590.00');
        }else if(parsedValue == 0) {     
            setInputValue('S/ 0.00');
        }else{
            setInputValue('S/ 354.00');
        }
        if(props.type == 2)
            setInputValue('S/ 2000.00');
        if(props.type == 3)
            setInputValue('S/ 4000.00');
    }, [isChecked, mountValue]);

    const validationSchema = Yup.object().shape({
        // money: Yup.string().trim().required("El campo moneda es requerido"),
        // amount: Yup.string()
        //     .matches(/^\d+(\.\d{1,2})?$/, 'El formato de cantidad debe ser 123.45'),
        numDocDemandante: Yup.string()
            .matches(/^[0-9]+$/, 'El campo número documento demandante debe ser un número')
            .required("El campo número documento demandante es requerido"),
        nameDemandante: Yup.string().trim().required("El campo nombre demandante es requerido"),
        emailDemandante: Yup.string().trim().email().required("El campo email demandante es requerido"),
        numDocDemandado: Yup.string()
            .matches(/^[0-9]+$/, 'El campo número documento demandado debe ser un número')
            .required("El campo número documento demandado es requerido"),
        nameDemandado: Yup.string().trim().required("El campo nombre demandado es requerido"),
        emailDemandado: Yup.string().trim().email().required("El campo email demandado es requerido"),
        rucFactura: Yup.string().trim()
            .matches(/^[0-9]+$/, 'El campo número ruc facturación debe ser un número')
            .length(11, 'El campo número ruc facturación debe tener 11 caracteres')
            .matches(/^(10|20)/, 'El campo número ruc facturación debe comenzar con 10 o 20')
            .required("El campo número ruc facturación es requerido"),
        nameFactura: Yup.string().trim().required("El campo razón social facturación es requerido"),
        emailFactura: Yup.string().trim().email().required("El campo email facturación requerido"),
    });

    if (loading) {
        return <Loading />
    } else if (finalizado) {
        return <Confirmation dateTime={time} />
    }
    else {
        return (
            <div className="container my-10 mx-auto bg-white rounded-lg p-5">
                {!isAuthenticated && (
                    <Navigate to="/" replace={true} />
                )}
                <img src="./logo_arbitraje.png" alt="Logo" className='w-56 mx-auto' />
                <h2 className='block text-gray-600 font-medium text-xl mb-2'>Formulario</h2>
                <Formik
                    initialValues={{
                        description: "",
                        filepet: [],
                        money: "",
                        mountValue: 0,
                        acceptIndeterminada: false,
                        files: [],
                        typeDocDemandante: "",
                        numDocDemandante: "",
                        nameDemandante: "",
                        addressDemandante: "",
                        addressArbDemandante: "",
                        emailDemandante: "",
                        phoneDemandante: "",
                        repreDemandante: "",
                        typeDocDemandado: "",
                        numDocDemandado: "",
                        nameDemandado: "",
                        addressDemandado: "",
                        addressArbDemandado: "",
                        emailDemandado: "",
                        phoneDemandado: "",
                        repreDemandado: "",
                        rucFactura: "",
                        nameFactura: "",
                        emailFactura: "",
                        filevoucher: [],
                        bank: "",
                        price: "",
                        invoice: "",
                    }}
                    validationSchema={
                        validationSchema
                    }
                    onSubmit={(values, actions) => {
                        setLoading(true);
                        values.amount = +mountValue;
                        savePetition(values).then(res => {
                            actions.setSubmitting(false);
                            setLoading(false);
                            sendEmail(user.email, user.name);
                            setFinalizado(true);
                        })
                    }}
                >
                    {({ touched, errors, isSubmitting, setFieldValue }) => (
                        <Form className='flex flex-col gap-y-4'>
                            <div className='flex flex-col md:flex-row gap-y-4 gap-x-4'>
                                <div className='w-full md:w-2/4 flex flex-col gap-y-2'>
                                    <div className="p-3 rounded-lg w-full border-gray-400 border">
                                        <label className=''>Descripción de la controversia</label>
                                        <Field name="description" as="textarea" className="p-3 w-full focus:outline-none" rows="5" placeholder="Puede completar este campo o adjuntar un archivo (PDF y WORD no mayor a 50 mb)"></Field>
                                    </div>
                                </div>
                                <div className='w-full md:w-2/4 flex flex-col justify-between gap-y-2 p-3 rounded-lg border-gray-400 border'>
                                    <div className="">
                                        <label className=''>Solicitud adjunta</label>
                                        <input
                                            name="filepet"
                                            type="file"
                                            className="w-full"
                                            onChange={(event) => {
                                                setFieldValue("filepet", Array.from(event.currentTarget.files));
                                            }}
                                        />
                                    </div>
                                    {/* {errors.filepet && <small className='text-red-600 w-full'>{errors.filepet}</small>} */}
                                    <div className="">
                                        <label className='mt-1 block'>Anexos</label>
                                        <input
                                            className="block mt-1"
                                            name="files"
                                            type="file"
                                            multiple
                                            onChange={(event) => {
                                                setFieldValue("files", Array.from(event.currentTarget.files));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {props.type === 1 &&
                                <div className="p-3 rounded-lg w-full border-gray-400 border flex flex-col md:flex-row gap-x-4">
                                    <div className='w-full md:w-1/3'>
                                        <label className=''>Moneda</label>
                                        <Field name="money" as="select" className="p-3 w-full focus:outline-none">
                                            <option>Seleccione tipo de moneda *</option>
                                            <option value={1}>Soles</option>
                                            <option value={2}>Dólares</option>
                                            <option value={3}>Euros</option>
                                        </Field>
                                        {touched.money && errors.money && <small className='text-red-600 w-full'>{errors.money}</small>}
                                    </div>
                                    <div className='w-full md:w-1/3'>
                                        <label className='mt-3'>Cuantia</label>
                                        <Field
                                            name="amount"
                                            type="number"
                                            min="0"
                                            className="p-3 w-full focus:outline-none"
                                            placeholder="0.00"
                                            readOnly={isChecked}
                                            value={mountValue}
                                            onChange={e => setMountValue(e.target.value)}
                                        />
                                        {touched.amount && errors.amount && <small className='text-red-600 w-full'>{errors.amount}</small>}
                                    </div>
                                    <div className='w-full md:w-1/3'>
                                        <label>Indeterminada</label>
                                        <div className="flex items-center mt-3">
                                            <Field
                                                type="checkbox"
                                                className="block form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                name="acceptIndeterminada"
                                                checked={isChecked}
                                                onChange={() => setIsChecked(!isChecked)}
                                            />
                                            <label className="ml-3">
                                                <span className="text-xs font-medium leading-5 text-gray-700">
                                                    Si es cuantía indeterminada
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='flex flex-col md:flex-row gap-x-4'>
                                <div className='w-full md:w-2/4 flex flex-col gap-y-2 p-3 rounded-lg border-gray-400 border'>
                                    <label>Información de demandante *</label>
                                    <div role="group" aria-labelledby="my-radio-group" className='flex flex-col md:flex-row mt-2'>
                                        <label>
                                            <Field type="radio" className="mr-1" name="typeDocDemandante" value="1" />
                                            DNI
                                        </label>
                                        <label>
                                            <Field type="radio" className="mr-1" name="typeDocDemandante" value="2" />
                                            RUC
                                        </label>
                                        <label>
                                            <Field type="radio" className="mr-1" name="typeDocDemandante" value="3" />
                                            Carnet de Identidad
                                        </label>
                                    </div>
                                    <Field name="numDocDemandante" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese número de documento *" />
                                    {touched.numDocDemandante && errors.numDocDemandante && <small className='text-red-600 w-full'>{errors.numDocDemandante}</small>}
                                    <Field name="nameDemandante" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese nombre / razón social *" />
                                    {touched.nameDemandante && errors.nameDemandante && <small className='text-red-600 w-full'>{errors.nameDemandante}</small>}
                                    <Field name="addressDemandante" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese dirección real" />
                                    <Field name="addressArbDemandante" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese dirección para fines del arbitraje *" />
                                    <Field name="emailDemandante" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese correo electrónico *" />
                                    {touched.emailDemandante && errors.emailDemandante && <small className='text-red-600 w-full'>{errors.emailDemandante}</small>}
                                    <Field name="phoneDemandante" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese número de teléfono" />
                                    <Field name="repreDemandante" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese nombre de representante" />
                                </div>
                                <div className='w-full md:w-2/4 flex flex-col gap-y-2 p-3 rounded-lg border-gray-400 border'>
                                    <label>Información de demandado *</label>
                                    <div role="group" aria-labelledby="my-radio-group" className='flex flex-col md:flex-row mt-2'>
                                        <label>
                                            <Field type="radio" className="mr-1" name="typeDocDemandado" value="1" />
                                            DNI
                                        </label>
                                        <label>
                                            <Field type="radio" className="mr-1" name="typeDocDemandado" value="2" />
                                            RUC
                                        </label>
                                        <label>
                                            <Field type="radio" className="mr-1" name="typeDocDemandado" value="3" />
                                            Carnet de Identidad
                                        </label>
                                    </div>
                                    <Field name="numDocDemandado" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese número de documento *" />
                                    {touched.numDocDemandado && errors.numDocDemandado && <small className='text-red-600 w-full'>{errors.numDocDemandado}</small>}
                                    <Field name="nameDemandado" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese nombre / razón social *" />
                                    {touched.nameDemandado && errors.nameDemandado && <small className='text-red-600 w-full'>{errors.nameDemandado}</small>}
                                    <Field name="addressDemandado" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese dirección real" />
                                    <Field name="addressArbDemandado" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese dirección para fines del arbitraje" />
                                    <Field name="emailDemandado" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese correo electrónico *" />
                                    {touched.emailDemandado && errors.emailDemandado && <small className='text-red-600 w-full'>{errors.emailDemandado}</small>}
                                    <Field name="phoneDemandado" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese número de teléfono" />
                                    <Field name="repreDemandado" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese nombre de representante" />
                                </div>
                            </div>
                            <div className='w-full flex flex-col gap-y-3 p-3 rounded-lg border-gray-400 border'>
                                <label>Derechos arbitrales</label>
                                <div className="flex flex-col md:flex-row gap-x-4">
                                    <div className='flex flex-col w-full md:w-1/3 '>
                                        <Field
                                            name="price"
                                            type="text"
                                            className="p-3 w-full focus:outline-none text-xl font-medium"
                                            value={inputValue}
                                            readOnly
                                        />
                                        <small>Derecho de presentación Inc. IGV</small>
                                    </div>
                                    <div role="group" aria-labelledby="my-radio-group" className='flex flex-row mt-2 w-fuul md:w-2/3'>
                                        <label>
                                            <Field type="radio" className="mr-1" name="invoice" value="1" />
                                            Boleta
                                        </label>
                                        <label>
                                            <Field type="radio" className="mr-1" name="invoice" value="2" />
                                            Factura
                                        </label>
                                    </div>
                                </div>
                                <label>Datos de facturación **</label>
                                <div className="flex flex-col md:flex-row gap-x-4">
                                    <div className='w-full md:w-1/3 flex flex-col'>
                                        <Field name="rucFactura" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese número de ruc *" />
                                        {touched.rucFactura && errors.rucFactura && <small className='text-red-600 w-full'>{errors.rucFactura}</small>}
                                    </div>
                                    <div className='w-full md:w-1/3 flex flex-col'>
                                        <Field name="nameFactura" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese razón social *" />
                                        {touched.nameFactura && errors.nameFactura && <small className='text-red-600 w-full'>{errors.nameFactura}</small>}
                                    </div>
                                    <div className='w-full md:w-1/3 flex flex-col'>
                                        <Field name="emailFactura" type="text" className="p-3 w-full focus:outline-none" placeholder="Ingrese correo electrónico *" />
                                        {touched.emailFactura && errors.emailFactura && <small className='text-red-600 w-full'>{errors.emailFactura}</small>}
                                    </div>
                                </div>
                                <label>Adjunte voucher del pago y seleccione el banco</label>
                                <div className="flex flex-col md:flex-row gap-x-4">
                                    <input
                                        name="filevoucher"
                                        type="file"
                                        className="w-full md:w-1/4"
                                        required="true"
                                        onChange={(event) => {
                                            setFieldValue("filevoucher", Array.from(event.currentTarget.files));
                                        }}
                                    />
                                    <div role="group" aria-labelledby="my-radio-group" className='flex flex-col md:flex-row justify-center mt-2 w-full md:w-3/4'>
                                        <div className='flex-1'>
                                            <label>
                                                <Field type="radio" className="mr-1" name="bank" value="1" />
                                                BCP
                                            </label>
                                            <small className='block text-xs'>
                                                C. Corriente soles: <strong>215-00144900-80</strong><br />
                                                CCI soles: <strong>002-215-000014490080-20</strong><br />
                                                C. Corriente dolares: <strong>215-1177074-1-62</strong><br />
                                                CCI dolares: <strong>002-215-001177074162-24</strong>
                                            </small>
                                        </div>
                                        <div className='flex-1'>
                                            <label>
                                                <Field type="radio" className="mr-1" name="bank" value="2" />
                                                BBVA
                                            </label>
                                            <small className='block text-xs'>
                                                C. Corriente soles: <strong>220-0100000403</strong><br />
                                                CCI soles: <strong>011-220-000100000403-14</strong><br />
                                                C. Corriente dolares: <strong>223-0100017574</strong><br />
                                                CCI dolares: <strong>011-223-000100017574-53</strong>
                                            </small>
                                        </div>
                                        <div className='flex-1'>
                                            <label>
                                                <Field type="radio" className="mr-1" name="bank" value="3" />
                                                Scotiabank
                                            </label>
                                            <small className='block text-xs'>
                                                C. Corriente soles: <strong>220-0100000403</strong><br />
                                                CCI soles: <strong>011-220-000100000403-14</strong><br />
                                                C. Corriente dolares: <strong>223-0100017574</strong><br />
                                                CCI dolares: <strong>011-223-000100017574-53</strong>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <small className='font-medium italic text-xs'>*Datos obligatorios | **Indicar la información necesaria para poder identificar el deposito.</small>
                                <input type="hidden" value={props.type} name="typedoc" />
                            </div>
                            <div className='flex flex-col text-center'>
                                {touched.money && errors.money && <small className='text-red-600 w-full'>{errors.money}</small>}
                                {touched.amount && errors.amount && <small className='text-red-600 w-full'>{errors.amount}</small>}
                                {touched.numDocDemandante && errors.numDocDemandante && <small className='text-red-600 w-full'>{errors.numDocDemandante}</small>}
                                {touched.nameDemandante && errors.nameDemandante && <small className='text-red-600 w-full'>{errors.nameDemandante}</small>}
                                {touched.emailDemandante && errors.emailDemandante && <small className='text-red-600 w-full'>{errors.emailDemandante}</small>}
                                {touched.numDocDemandado && errors.numDocDemandado && <small className='text-red-600 w-full'>{errors.numDocDemandado}</small>}
                                {touched.nameDemandado && errors.nameDemandado && <small className='text-red-600 w-full'>{errors.nameDemandado}</small>}
                                {touched.emailDemandado && errors.emailDemandado && <small className='text-red-600 w-full'>{errors.emailDemandado}</small>}
                                {touched.rucFactura && errors.rucFactura && <small className='text-red-600 w-full'>{errors.rucFactura}</small>}
                                {touched.nameFactura && errors.nameFactura && <small className='text-red-600 w-full'>{errors.nameFactura}</small>}
                                {touched.emailFactura && errors.emailFactura && <small className='text-red-600 w-full'>{errors.emailFactura}</small>}
                                <button type="submit" className='bg-[#BC1F28] text-white text-xl px-12 uppercase py-3 mt-2 rounded-md hover:bg-red-800 w-full md:w-auto mx-auto' disabled={isSubmitting}>
                                    Ingresar solicitud
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
};

export default FormPetition;
