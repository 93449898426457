import React from 'react';
import LineMdLoadingAltLoop from './LineMdLoadingAltLoop';

const Loading = () => (
    <div className="h-screen w-full flex flex-col items-center justify-center">
        <LineMdLoadingAltLoop />
        <p className='text-sm'>Sus archivos están siendo subidos a nuestros servidores. Espere un momento por favor.</p>
    </div>
);

export default Loading;