import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';

const Menu = () => {

    const { isAuthenticated } = useAuth0()

    return (
        <div className="h-screen w-full flex flex-col items-center justify-center gap-y-8">
            {!isAuthenticated && (
                <Navigate to="/" replace={true} />
            )}
            <h1 className='text-xl uppercase font-semibold'>Seleccione una opción</h1>
            <div className='flex items-center justify-center gap-6'>
                <Link to={'/arbitral'} className='flex flex-col justify-center items-center gap-y-3 max-w-xs border border-gray-400 pb-3 bg-white rounded-br-lg rounded-bl-lg hover:shadow-2xl transition-all duration-200'>
                    <img src='https://img.freepik.com/vector-gratis/mano-sujetando-lapiz-rellenando-formulario_23-2147613534.jpg' className='w-[200px]' alt='Solicitud arbitral' />
                    <h3 className='text-sm uppercase font-semibold text-center'>Solicitud de<br />ingreso de<br />petición arbitral</h3>
                </Link>
                <Link to={'/designacion'} className='flex flex-col justify-center items-center gap-y-3 max-w-xs border border-gray-400 pb-3 bg-white rounded-br-lg rounded-bl-lg hover:shadow-2xl transition-all duration-200'>
                    <img src='https://img.freepik.com/vector-premium/puntos-control-manual-documento-medico-mano-femenina-poniendo-garrapatas-lapiz-concepto-medicina-banner-diseno-sitio-web-o-pagina-web-destino_74855-26007.jpg' className='w-[200px]' alt='Solicitud arbitral' />
                    <h3 className='text-sm uppercase font-semibold text-center'>Solicitud designación<br />árbitro en arbitrajes<br />AD HOC</h3>
                </Link>
                <Link to={'/recusacion'} className='flex flex-col justify-center items-center gap-y-3 max-w-xs border border-gray-400 pb-3 bg-white rounded-br-lg rounded-bl-lg hover:shadow-2xl transition-all duration-200'>
                    <img src='https://img.freepik.com/vector-premium/examen-linea-verde_123447-526.jpg' className='w-[200px]' alt='Solicitud arbitral' />
                    <h3 className='text-sm uppercase font-semibold text-center'>Solicitud resolver<br />recusación en arbitraje<br />AD HOC</h3>
                </Link>
            </div>
        </div>
    )
}

export default Menu