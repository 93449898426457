import React from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom'

const LoginAccess = () => {

    const { loginWithRedirect, isAuthenticated } = useAuth0()

    return (
        <>
            {isAuthenticated && (
                <Navigate to="/menu" replace={true} />
            )}
            <div className="h-screen w-full flex justify-center items-center">
                <div className="w-full max-w-lg bg-white rounded-lg p-5 text-center">
                    <img src="./logo_arbitraje.png" alt="Logo" className='w-64 mx-auto' />
                    <h2 className='block text-gray-600 font-medium text-xl mb-2'>Ingreso de solicitud</h2>
                    <p className='text-gray-600'>Para acceder a la plataforma ingrese sus credenciales o regístrese.</p>
                    <button onClick={() => loginWithRedirect()} className="bg-[#BC1F28] text-white px-10 py-2 mt-5 rounded-md hover:bg-red-800">Acceder</button>
                </div>
            </div>
        </>
    )
}

export default LoginAccess