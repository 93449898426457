import React from 'react'

const Error404 = () => {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center gap-y-8">
        <h1 className='text-5xl font-bold'>Error 404</h1>
        <p>Oooops, página no encontrada</p>
    </div>
  )
}

export default Error404
