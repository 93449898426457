import axios from 'axios'

export async function savePetition(petitionData) {
    const formData = new FormData()
    formData.append('descripcion', petitionData.description)
    formData.append('archivo', petitionData.filepet[0])
    var ins = petitionData.files.length;
    for (var x = 0; x < ins; x++) {
        formData.append("anexo[]", petitionData.files[x]);
    }
    formData.append('moneda', petitionData.money)
    formData.append('monto', petitionData.amount)

    formData.append('documento-dt', petitionData.typeDocDemandante)
    formData.append('nro-documento-dt', petitionData.numDocDemandante)
    formData.append('razon-social-dt', petitionData.nameDemandante)
    formData.append('direccion-real-dt', petitionData.addressDemandante)
    formData.append('direccion-arb-dt', petitionData.addressArbDemandante)
    formData.append('correo-dt', petitionData.emailDemandante)
    formData.append('telefono-dt', petitionData.phoneDemandante)
    formData.append('repre-nom-dt', petitionData.repreDemandante)

    formData.append('documento-dd', petitionData.typeDocDemandado)
    formData.append('nro-documento-dd', petitionData.numDocDemandado)
    formData.append('razon-social-dd', petitionData.nameDemandado)
    formData.append('direccion-real-dd', petitionData.addressDemandado)
    formData.append('direccion-arb-dd', petitionData.addressArbDemandado)
    formData.append('correo-dd', petitionData.emailDemandado)
    formData.append('telefono-dd', petitionData.phoneDemandado)
    formData.append('repre-nom-dd', petitionData.repreDemandado)

    formData.append('monto-presentacion', petitionData.price)
    formData.append('comprobante', petitionData.invoice)
    formData.append('ruc', petitionData.rucFactura)
    formData.append('razon-social-fac', petitionData.nameFactura)
    formData.append('correo-fac', petitionData.emailFactura)
    formData.append('voucher', petitionData.filevoucher[0])
    formData.append('pago', petitionData.bank)
    formData.append('type', petitionData.typedoc)

    await axios
        .post('https://www.arbitrajeccia.com.pe/sistema/restpetitions', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
}

export async function sendEmail(email, name) {
    const response = await fetch("https://www.arbitrajeccia.com.pe/sistema/api/sendEmailPetition.php", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
            email: email,
            name: name
        })
    });
    return response.json();
}