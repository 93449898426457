import React from 'react'
import LoginAccess from '../../components/LoginAccess'

const Login = () => {
  return (
    <div className="flex items-center justify-center relative">
        <LoginAccess />
    </div>
  )
}

export default Login