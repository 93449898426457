import React from 'react';

const Confirmation = ({ dateTime }) => (
    
    <div className="h-screen flex flex-col items-center justify-center">
        <img src="/logo_arbitraje.png" alt="Logo" className='w-56 mx-auto' />
        <h1 className='font-bold text-2xl mb-5'>Confirmación de envío</h1>
        <p className='text-sm'>Su solicitud fue enviada correctamente.<br />
        Fecha y Hora: {dateTime}<br/>
        Gracias</p>
    </div>
);

export default Confirmation;