import './App.css';
// import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

// import LoginAccess from './components/LoginAccess';
import Login from './pages/Login';
import Menu from './pages/Menu';
import FormPetition from './components/FormPetition';
import Error404 from './pages/Error404';

function App() {

  // const { isAuthenticated } = useAuth0()

  return (
    // <div className="flex items-center justify-center relative">
    //   {
    //     !isAuthenticated ? <LoginAccess /> : <FormPetition />
    //   }
    // </div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/arbitral" element={<FormPetition type={1} />} />
        <Route path="/designacion" element={<FormPetition type={2} />} />
        <Route path="/recusacion" element={<FormPetition type={3} />} />
        <Route path="/*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;